import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby";
import { pathTo } from '../../routes';

import { header, topline, container, bottom, logo, navigation, navItem, active } from "./index.module.scss";

import logoImage from '../../assets/images/logo.gif';

export default class Header extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allContentfulNavigationZone(filter: {identifier: {eq: "Main"}}) {
              edges {
                node {
                  identifier
                  items {
                    title
                    slug
                    __typename
                    contentful_id
                  }
                }
              }
            }
          }
        `}
        render={data => {

          const navItems = data.allContentfulNavigationZone.edges[0].node.items.map(item => {
            return (
              <Link key={item.contentful_id} to={pathTo(item)} className={navItem} activeClassName={active}>{item.title}</Link>
            )
          })

          return (
            <div className={header}>
              <div className={topline}>
                <div className={container}>
                  topline
                </div>
              </div>
              <div className={bottom}>
                <div className={logo}>
                  <Link to='/'>
                    <img src={logoImage} alt='NOMAD Chemnitz Logo'/>
                  </Link>
                </div>
                <div className={navigation}>
                  {navItems}
                </div>
              </div>
            </div>
          )
        }}
      />
    );
  }
}
