import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../header/index";
import Footer from '../footer/index';
import {} from "./index.module.scss"

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet>
            <meta name="health-token" content="a005fd13-3249-4958-867d-72d140a5384c"/>
          </Helmet>
          <Header/>
          <div>
            {children}
          </div>
          <Footer/>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


// <main>{children}</main>
