import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import { textStyles } from './renderer.module.scss';

class TextRenderer extends Component {
  render() {
    const { text } = this.props;

    if (!text) { return null; };

    return (
      <div className={textStyles}>
        <ReactMarkdown source={text} plugins={[breaks]}/>
      </div>
    );
  }
}

TextRenderer.propTypes = {
  text: PropTypes.string
}

export default TextRenderer;
