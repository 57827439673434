import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby";

import TextRenderer from '../text/renderer';
import { pathTo } from '../../routes';

import { footerClass, containerClass, topClass, hoursClass, contactClass, diningClass, bottomClass, copyrightClass, metaNavigationClass, efreClass } from "./index.module.scss";
import efre from '../../assets/images/efre.svg';

export default class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            contact: allContentfulText(filter: {title: {eq: "Contact"}}) {
              edges {
                node {
                  title
                  text {
                    text
                  }
                }
              }
            }
            hours: allContentfulText(filter: {title: {eq: "Hours"}}) {
              edges {
                node {
                  title
                  text {
                    text
                  }
                }
              }
            }
            privateDining: allContentfulText(filter: {title: {eq: "Private Dining Footer Info"}}) {
              edges {
                node {
                  title
                  text {
                    text
                  }
                }
              }
            }
            site {
              siteMetadata {
                facebook
                instagram
                copyright
              }
            }
            metaNavigation: allContentfulNavigationZone(filter: {identifier: {eq: "Meta"}}) {
              edges {
                node {
                  identifier
                  items {
                    title
                    slug
                    __typename
                    contentful_id
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { contact, hours, privateDining, metaNavigation } = data;
          const { facebook, instagram, copyright } = data.site.siteMetadata;

          const navItems = metaNavigation.edges[0].node.items.map(item => {
            return <Link key={item.contentful_id} to={pathTo(item)}>{item.title}</Link>
          })

          return (
            <div className={footerClass}>
              <div className={containerClass}>
                <div className={topClass}>
                  <div className={hoursClass}>
                    <TextRenderer text={hours.edges[0].node.text.text}/>
                  </div>
                  <div className={diningClass}>
                    <TextRenderer text={privateDining.edges[0].node.text.text}/>
                  </div>
                  <div className={contactClass}>
                    <TextRenderer text={contact.edges[0].node.text.text}/>
                  </div>
                </div>
                <div className={bottomClass}>
                  <div>
                    <a target='_blank' href={instagram} rel="noopener noreferrer">
                      <i className={'fab fa-instagram'}/>
                    </a>
                    <a target='_blank' href={facebook} rel="noopener noreferrer">
                      <i className={'fab fa-facebook'}/>
                    </a>
                  </div>
                </div>
                <div className={efreClass}>
                  <img src={efre} alt="" title=""/>
                </div>
                <div className={copyrightClass}>
                  <TextRenderer text={copyright}/>
                </div>
                <div className={metaNavigationClass}>
                  {navItems}
                </div>
              </div>
            </div>
          )
        }}
      />
    );
  }
}
