// extracted by mini-css-extract-plugin
export var bottomClass = "index-module--bottomClass--NMrYf";
export var contactClass = "index-module--contactClass--JIOjm";
export var containerClass = "index-module--containerClass--ua7zB";
export var copyrightClass = "index-module--copyrightClass--fjenY";
export var diningClass = "index-module--diningClass--xMNVY";
export var efreClass = "index-module--efreClass--78Lao";
export var fadein = "index-module--fadein--NNweW";
export var footerClass = "index-module--footerClass--x2CFw";
export var hoursClass = "index-module--hoursClass---BkW4";
export var metaNavigationClass = "index-module--metaNavigationClass--wgAvX";
export var topClass = "index-module--topClass--TpnUg";