/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"

import { Helmet } from "react-helmet"
import React from "react"

function Seo({ title, metaTitle, metaDescription, sharingTitle, sharingDescription, sharingImage, currentUrl, lang, keywords=[] }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
          }
        }
      }
    `
  );

  const completeTitle = `${title} | ${site.siteMetadata.siteTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={completeTitle}
      titleTemplate={`%s`}
      link={[
        {
            href: process.env.WEBSITE_ROOT_URL + currentUrl,
            rel: "canonical"
        }
      ]}
      meta={[
        {
          name: `title`,
          content: metaTitle,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: completeTitle,
        },
        {
          property: `og:description`,
          content: sharingDescription,
        },
        {
          property: `og:image`,
          content: `http:${sharingImage}`,
        },
        {
          property: `og:url`,
          content: process.env.WEBSITE_ROOT_URL + currentUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: sharingTitle,
        },
        {
          name: `twitter:description`,
          content: sharingDescription,
        },
        {
          name: `twitter:image`,
          content: sharingImage,
        },
        {
          name: `twitter:image:alt`,
          content: sharingTitle,
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )}
    />
  )
}

Seo.defaultProps = {
  lang: `de`,
  title: '',
  metaTitle: '',
  metaDescription: '',
  sharingTitle: '',
  sharingDescription: '',
  sharingImage: '',
  currentUrl: '',
  keywords: [],
}

export default Seo;
